import React from 'react';
import Layout from '../components/layout';
import Title from '../components/title';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function NoMatch() {

    return (
        <Layout>
            <Helmet>
                <title>Page non trouvée</title>
                <meta name="description" content="404. Cette page n'existe pas" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            <Title title={"Oups... Cette page n'existe pas."} />
            <div className="px-5 md:max-w-4xl m-auto my-10 text-center">
                <p className="mb-5 ">Pour revenir sur une page accessible, vous pouvez cliquer sur un des liens ci-dessous :</p>
                <p className="mb-4 text-black"><Link to="/" className="linkBlue">Accueil</Link></p>
                <p className="mb-4 text-black"><Link to="/articles" className="linkBlue">Articles</Link></p>
                <p className="mb-4 text-black"><Link to="/expertise-batiment" className="linkBlue">Expertise bâtiment</Link></p>
                <p className="mb-4 text-black"><Link to="/contact" className="linkBlue">Contact</Link></p>
            </div>
        </Layout>
    );
}
