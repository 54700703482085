import React from 'react';
import Footer from '../components/footer';
import Layout from '../components/layout';
import Title from '../components/title';
import { Loader } from '../components/loader';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { storyblokToken, getPageContent, renderHtmlContent } from '../helpers/storyblok';

export default function Mediation() {
    const { data: dataContent } = useQuery('getMediationContent', () => getPageContent('abc-experts-static/mediation', storyblokToken));
    const content = dataContent || null;

    return (
        <Layout>
            <Helmet>
                <title>ABC Experts - Médiation</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/mediation`} />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Qu'est ce que la médiation ?" />
            </Helmet>
            {content === null ?
                <Loader />
                :
                <div className="px-5 md:max-w-4xl m-auto my-10">
                    <Title title={content.title} />
                    <div
                        dangerouslySetInnerHTML={{ __html: renderHtmlContent(content.content) }}
                        className='max-w-2xl m-auto my-10 list-type'
                    />
                </div>
            }
            <Footer />
        </Layout>
    );
}
