import React from 'react';
import BackgroundHomePage from '../components/backgroundHomePage';
import Layout from '../components/layout';
import Footer from '../components/footer';
import { Loader } from '../components/loader';
import PortraitImage from '../components/portraitImage';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { storyblokToken, getPageContent, renderHtmlContent } from '../helpers/storyblok';

export default function Home() {
    const { data: dataContent } = useQuery('getHomepageContent', () => getPageContent('abc-experts-static/abcexperts-homepage', storyblokToken));
    const content = dataContent || null;

    return (
        <Layout>
            <Helmet>
                <title>ABC Experts</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}`} />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Bienvenue sur le site ABCEXPERTS" />
            </Helmet>
            <BackgroundHomePage content={content} />
            {content === null ?
                <Loader />
                :
                <div className="px-5 md:max-w-4xl m-auto mt-20">
                    <div className="md:flex md:justify-center md:space-x-10">
                        <PortraitImage imageUrl={content.image_expert_1.filename} alt={content.image_expert_1.name} />
                        <div className='mb-10'>
                            <p className="mt-2 font-bold text-2xl text-black">{content.sectionTitle}</p>
                            <div dangerouslySetInnerHTML={{ __html: renderHtmlContent(content.expert_1_description) }} />
                        </div>
                    </div>
                    <div className="md:flex md:justify-center md:space-x-10">
                        <PortraitImage imageUrl={content.image_expert_2.filename} alt={content.image_expert_2.name} />
                        <div>
                            <div
                                dangerouslySetInnerHTML={{ __html: renderHtmlContent(content.expert_1_description) }}
                                className='mb-10'
                            />
                            <div className="mb-20">
                                <Link to="/contact" className="btn">{content.ctaButton}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </Layout>
    );
}
