import React from 'react';

export default function MainImage(props) {

    return (
        <div>
            <div className=" border-gray border w-full p-1.5">
                <img src={props.imageUrl} alt={props.alt} className="w-full" />
            </div>
        </div>
    );
}