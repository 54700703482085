import React from 'react';
import Layout from '../components/layout';
import MainImage from '../components/mainImage';
import Title from '../components/title';
import { Helmet } from 'react-helmet-async';
import { Loader } from '../components/loader';
import Footer from '../components/footer';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { storyblokToken, getPageContent, renderHtmlContent } from '../helpers/storyblok';

export default function Article() {
    const { slug } = useParams();
    const { data: dataContent } = useQuery('getArticleContent', () => getPageContent(`abc-experts-articles/${slug}`, storyblokToken, { cacheTime: 0 }));
    const content = dataContent || null;

    if (dataContent === null) {
        return <Redirect to="/page-not-found" />;
    }

    return (
        <Layout>
            <Helmet>
                <title>ABC Experts - Article</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/articles`} />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Liste des articles ABCEXPERTS" />
            </Helmet>
            <div className="px-5 md:max-w-4xl m-auto my-10">
                {content === null ?
                    <Loader />
                    :
                    <div className="px-5 md:max-w-4xl m-auto my-10">
                        <Title title={content.title} />
                        {content.image.filename !== "" &&
                            <MainImage imageUrl={content.image.filename} alt={content.image.alt} />
                        }
                        <div
                            dangerouslySetInnerHTML={{ __html: renderHtmlContent(content.content) }}
                            className='max-w-2xl m-auto my-10'
                        />
                    </div>
                }
            </div>
            <Footer />
        </Layout>
    );
}
