import React from 'react';

export default function PortraitImage(props) {

    return (
        <div>
            <div className=" border-gray border rounded-full w-60 p-1.5 m-auto mb-5">
                <img src={props.imageUrl} alt={props.alt} className="rounded-full w-60" />
            </div>
        </div>
    );
}
