import request from './request';
import StoryblokClient from 'storyblok-js-client';

export const storyblokToken = "Rr6zsYKaJjbCAOg9zKJWOQtt";

export async function getPageContent(page) {
    const url = `https://api.storyblok.com/v2/cdn/stories/${page}?token=${storyblokToken}&version=published`;

    return await request(url, "GET", null, true).then((res) => {
        if (res.status === 200) {
            return res.json.story.content;
        }
        return null;
    })
}

export async function getArticles() {
    const Storyblok = new StoryblokClient({ accessToken: storyblokToken });

    return Storyblok.get('cdn/stories', {
        "starts_with": "abc-experts-articles/",
        "version": "published",
    }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
    });
}

export function renderHtmlContent(content) {
    const Storyblok = new StoryblokClient({ accessToken: storyblokToken });

    return Storyblok.richTextResolver.render(content).replace(/<b>/g, "<strong>").replace(/<\/b>/g, "</strong>");
}
