import React from 'react';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

export default function ArticleSummary(props) {

    return (
        <Paper className="pb-5 article">
            <Link to={props.url} >
                <img className="rounded rounded-b-none" src={props.imageSrc} alt={props.imageAlt} />
                <div className="text-center p-5">
                    <div>
                        <h1 className="mb-5 mt-2 text-3xl">{props.title}</h1>
                    </div>
                    <div>
                        <p>{props.intro}</p>
                    </div>
                </div>
            </Link>
        </Paper>
    );
}
