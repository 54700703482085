import React from 'react';

export default function Title(props) {

    return (
        <div className="mt-32 text-center mb-5">
            <h1 className="text-4xl font-extrabold text-black">{props.title}</h1>
        </div>
    );
}
