import React from 'react';

export default function BackgroundHomePage(props) {
    const content = props.content;

    return (
        <section id="banner">
            <div id="inner">
                <header className="text-white flex items-center h-full">
                    <div className="text-center m-auto w-full px-5">
                        <h1 className="text-6xl font-bold">{content ? content.title : ''}</h1>
                        <div className="text-2xl font-bold m-auto">
                            <p className="my-10">
                                <strong>{content ? content.subtitle1 : ''}</strong>{content?.subtitle1 ? ' dans la RÉGION SUD' : ''}
                            </p>
                            <div className="md:flex md:justify-center">
                                <p><strong>{content ? 'Françoise Clément' : ''}</strong>{content?.subtitle2 ?? ''}</p>
                            </div>

                        </div>
                    </div>
                </header>
            </div>
        </section>
    );
}
