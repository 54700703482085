import React from 'react';
import Layout from '../components/layout';
import Title from '../components/title';
import { Loader } from '../components/loader';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/footer';
import { useQuery } from 'react-query';
import { storyblokToken, getPageContent, renderHtmlContent } from '../helpers/storyblok';

export default function MentionsLegales() {
    const { data: dataContent } = useQuery('getLegalNoticeContent', () => getPageContent('abc-experts-static/mentions-legales', storyblokToken));
    const content = dataContent || null;

    return (
        <Layout>
            <Helmet>
                <title>ABC Experts - Mentions légales</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/mentions-legales`} />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Mentions légales" />
            </Helmet>
            {content === null ?
                <Loader />
                :
                <section>
                    <Title title={content.title} />
                    <div
                        dangerouslySetInnerHTML={{ __html: renderHtmlContent(content.content) }}
                        className='max-w-4xl m-auto my-10 list-type'
                    />
                </section>
            }
            <Footer />
        </Layout>
    );
}
