import React from 'react';
import Layout from '../components/layout';
import MainImage from '../components/mainImage';
import Title from '../components/title';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import { Loader } from '../components/loader';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { storyblokToken, getPageContent, renderHtmlContent } from '../helpers/storyblok';

export default function ExpertiseBatiment() {
    const { data: dataContent } = useQuery('getExpertiseContent', () => getPageContent('abc-experts-static/expertise-batiment', storyblokToken));
    const content = dataContent || null;

    return (
        <Layout>
            <Helmet>
                <title>Abc Experts - Expertise bâtiment</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/expertise-batiment`} />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Expertise bâtiments ABCEXPERTS" />
            </Helmet>
            {content === null ?
                <Loader />
                :
                <div className="px-5 md:max-w-4xl m-auto my-10">
                    <Title title={content.title} />
                    <MainImage imageUrl={content.image.filename} alt="mur fissure" />
                    <div
                        dangerouslySetInnerHTML={{ __html: renderHtmlContent(content.content) }}
                        className='max-w-2xl m-auto my-10'
                    />
                    <div className="mb-20 m-auto w-full text-center">
                        <Link to="/contact" className="btn">{content.cta}</Link>
                    </div>
                </div>
            }
            <Footer />
        </Layout>
    );
}
