import CustomRouter from './components/customRouter';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <HelmetProvider>
                <CustomRouter />
            </HelmetProvider>
        </QueryClientProvider>

    );
}

export default App;
