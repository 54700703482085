import React from 'react';
import Layout from '../components/layout';
import Title from '../components/title';
import ArticleSummary from '../components/articleSummary';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/footer';
import { Loader } from '../components/loader';
import { useQuery } from 'react-query';
import { getArticles } from '../helpers/storyblok';

export default function Articles() {
    const { data: dataContent } = useQuery('getArticleThumbnails', getArticles);
    const articles = dataContent || null;

    return (
        <Layout>
            <Helmet>
                <title>ABC Experts - Articles</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/articles`} />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Liste des articles ABCEXPERTS" />
            </Helmet>
            <div className="px-5 md:max-w-4xl m-auto my-10">
                <Title title={"Dernières actualités"} />
                {articles === null ?
                    <Loader />
                    :
                    <div className="mt-10 grid grid-cols-1 gap-10 md:grid-cols-2">
                        {articles.data.stories.map((article) => (
                            <ArticleSummary
                                key={article.uuid}
                                title={article.content.title}
                                imageSrc={article.content.image.filename}
                                imageAlt={article.content.image.alt}
                                url={`/article/${article.slug}`}
                                intro={article.content.short_description}
                            />
                        ))}
                    </div>
                }
            </div>
            <Footer />
        </Layout>
    );
}
